import React from 'react'

import SEO from '../../components/SEO'
import Layout from '../../components/Layout'
import styles from './privacy.module.scss'

const Privacy = () => {
  return (
    <Layout>
      <SEO title="Privacy Policy" />
      <main className={styles.root}>
        <h2>Privacy Policy</h2>
        <p>
          Your privacy is important to Cardinal Blue. This privacy statement
          provides information about the personal information that Cardinal Blue
          collects, and the ways in which Cardinal Blue uses that personal
          information.
        </p>

        <h2>Personal information collection</h2>

        <p>
          Cardinal Blue may collect and use the following kinds of information
          generated by the user in the application:
        </p>

        <ol class="lower-alpha">
          <li>
            information about your use of our applications including aggregate
            information concerning what pages within the application users
            access or visit, information volunteered by the user such as survey
            information and messages to community moderators
          </li>
          <li>
            information about transactions carried out over this website
            including free content download transactions;
          </li>
        </ol>

        <p>Cardinal Blue may use your personal information to:</p>
        <ol class="lower-alpha">
          <li>administer the application</li>
          <li>personalize the application for you;</li>
          <li>enable your access to and use of the application services;</li>
          <li>send to you products that you purchase;</li>
          <li>provide to you services and content that you download;</li>
          <li>send you statements and invoices; and</li>
          <li>send you marketing communications.</li>
        </ol>
        <p>
          Where Cardinal Blue discloses your personal information to its agents
          or sub-contractors for these purposes, the agent or sub-contractor in
          question will be obligated to use that personal information in
          accordance with the terms of this privacy statement.
        </p>
        <p>
          In addition to the disclosures reasonably necessary for the purposes
          identified elsewhere above, Cardinal Blue may disclose your personal
          information to the extent that it is required to do so by law, in
          connection with any legal proceedings or prospective legal
          proceedings, and in order to establish, exercise or defend its legal
          rights.
        </p>

        <p>Cardinal Blue acknowledges that:</p>
        <ol class="lower-alpha">
          <li>
            We will honor parents’ requests for deletion of student data.
            Requests must be made by contacting PicCollage EDU at
            info@cardinalblue.com.
          </li>
          <li>Student data is not used for commercial purposes.</li>
          <li>
            Data collected through PicCollage EDU is retained only for education
            purposes.
          </li>
        </ol>

        <h2>Data Privacy</h2>
        <ol class="decimal">
          <li>
            The information we collect from you includes the country you set
            your device to, the language you use on your device, the type and
            version of the operating system of your device, and your device
            model. If you make any in-app purchases, we will also collect the
            product IDs of the items you purchase.
          </li>

          <li>
            We collect this information from you to improve our app’s overall
            performance and the service we provide. We analyze this information
            with tools provided by third party companies. These companies
            include:
            <ol>
              <li>
                <a
                  href="https://developer.yahoo.com/flurry/legal-privacy/terms-service/flurry-analytics-terms-service.html"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Flurry
                </a>
                ,{' '}
                <a
                  href="https://firebase.google.com/terms/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Firebase
                </a>
                ,{' '}
                <a
                  href="https://www.google.com/analytics/terms/us.html"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Google
                </a>
                ,{' '}
                <a
                  href="https://amplitude.com/terms"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Amplitude
                </a>
              </li>

              <li>
                All these companies are GDPR-compliant. You can learn more about
                the information they collect and analyze to ensure that
                PicCollage provides optimum user experience by clicking on the
                links above.
              </li>
            </ol>
          </li>

          <li>
            To provide users with an efficient way to communicate with
            PicCollage, we use{' '}
            <a
              href="https://www.zendesk.com/company/customers-partners/privacy-and-data-protection/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Zendesk
            </a>
            , a customer service platform, to receive your feedback and offer
            timely responses to any questions you have. Please note we will
            receive your IP address and email address when you try to contact us
            through Zendesk.
          </li>
        </ol>

        <h2>Securing your data</h2>
        <p>
          Cardinal Blue will take reasonable technical and organizational
          precautions to prevent the loss, misuse or alteration of your personal
          information. Cardinal Blue will store all the personal information you
          provide on its secure servers.
        </p>

        <h2>Cross-border data transfers</h2>
        <p>
          Information that Cardinal Blue collects may be stored and processed in
          and transferred between any of the countries in which Cardinal Blue
          operates to enable the use of the information in accordance with this
          privacy policy.
        </p>
        <p>You agree to such cross-border transfers of personal information.</p>

        <h2>Updating this statement</h2>
        <p>
          Cardinal Blue may update this privacy policy by posting a new version
          on this website. You should check this page occasionally to ensure you
          are familiar with any changes.
        </p>

        <h2>Other websites</h2>
        <p>
          This application contains links to other applications and websites.
          Cardinal Blue is not responsible for the privacy policies or practices
          of any third party.
        </p>

        <h2>Contact Cardinal Blue</h2>
        <p>
          If you have any questions about this privacy policy or Cardinal Blue’s
          treatment of your personal information, contact{' '}
          <a href="mailto:info@cardinalblue.com">info@cardinalblue.com</a>.
        </p>

        <h2>This privacy statement</h2>
        <p>
          This privacy statement is based on a precedent created by
          <a href="https://employmentlawcontracts.co.uk">
            employmentlawcontracts.co.uk
          </a>
          and published by <a href="https://freenetlaw.com">freenetlaw.com</a>.
        </p>

        <p>Updated: Jan 2018</p>
      </main>
    </Layout>
  )
}

export default Privacy
